const users = [
    {
      path: '/users/edit',
      name: 'users-edit',
      component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
      meta: {
        layout: 'content',
        resource: 'Editor',
        action: 'read',
      },
    },
  ]
  
  export default users