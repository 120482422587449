const materials = [
  {
    path: '/materials/list',
    name: 'materials-list',
    component: () => import('@/views/apps/material/material-list/MaterialList.vue'),
    meta: {
      layout: 'content',
      resource: 'Editor',
      action: 'read',
    },
  },
  {
    path: '/materials/preview/:id',
    name: 'materials-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      layout: 'content',
      resource: 'Editor',
      action: 'read',
    },
  },
  {
    path: '/materials/add/',
    name: 'materials-add',
    component: () => import('@/views/apps/material/material-edit/MaterialEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Editor',
      action: 'read',
    },
  },
  {
    path: '/materials/edit/:id',
    name: 'materials-edit',
    component: () => import('@/views/apps/material/material-edit/MaterialEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Editor',
      action: 'read',
    },
  },
]

export default materials
