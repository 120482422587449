// eslint-disable-next-line object-curly-newline
import { mdiChartPie, mdiHomeAnalytics, mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Homepage',
    icon: mdiHomeOutline,
    to: 'main',
    resource: 'Editor',
    action: 'read',
  },
  {
    title: 'Admin Dashboard',
    icon: mdiHomeAnalytics,
    to: 'dashboard-admin',
    resource: 'Admin',
    action: 'read',
  },
  /*{
    title: 'Dashboard',
    icon: mdiChartPie,
    to: 'dashboard-material',
    resource: 'Editor',
    action: 'read',
  },*/
  
]

/*
export default [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    children: [
      {
        icon: mdiMonitorDashboard,
        title: 'CRM',
        to: 'dashboard-crm',
      },
      {
        icon: mdiChartTimelineVariant,
        title: 'Analytics',
        to: 'dashboard-analytics',
      },
      {
        icon: mdiCartOutline,
        title: 'eCommerce',
        to: 'dashboard-eCommerce',
      },
    ],
  },
]
*/
