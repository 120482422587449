import { mdiFormatListBulleted, mdiPackageVariantClosed, mdiPlaylistPlus } from '@mdi/js'

export default [
  {
    title: 'My Listings',
    icon: mdiPackageVariantClosed,
    children: [
      {
        title: 'All Listings',
        icon: mdiFormatListBulleted,
        to: 'materials-list',
        resource: 'Editor',
        action: 'read',
      },
      {
        title: 'Create a new post',
        icon: mdiPlaylistPlus,
        to: 'materials-add',
        resource: 'Editor',
        action: 'read',
      },
    ],
  },
]
