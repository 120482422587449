<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar>
      
      <v-col style="padding: 0px; margin-top: -25px;">
        <v-row>
          <v-col>
            <div id="main-logo" class="hero-logo">
              <a href="/">
                <img
                  src="@/assets/images/logos/main-logo.png"
                />
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            style="padding: 0px;"
          >
            <div
              class="hero-image sell-img"
              style="text-align: center;"
            ></div>
          </v-col>
          <v-col
            cols="6"
            style="padding: 0px;"
          >
            <div
              class="hero-image take-img"
              style="text-align: center;"
            ></div>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="py-0">
        <div class="d-flex justify-space-between" style="font-size: 10px;">
          <div class="d-flex align-center">
            COPYRIGHT &copy; {{ new Date().getFullYear() }} Material dirt trader Inc., All rights Reserved. Material dirt trader Inc. takes no liability or responsibility for any posts or exchanges made on materialtrader.ca. By using this free platform every user of this site takes responsibility for their own exchanges and/or posts created
          </div>
        </div>
      </div>  
    </template>

    <!-- App Content -->
    <template #v-app-content>
      <!--<app-customizer class="d-none d-md-block"></app-customizer>-->
    </template>
  </layout-content-horizontal-nav>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'

// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'

// App Bar Components
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import { ref, watch, onMounted } from 'vue'

import { mdiHeartOutline } from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  components: {
    LayoutContentHorizontalNav,

    // AppCustomizer,

    // App Bar Components
    // AppBarSearch,
    // AppBarI18n,
    // AppBarThemeSwitcher,
    AppBarUserMenu,

    // AppBarNotification,
  },
  setup() {
    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // onMounted
    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })

    const handleResize = () => {
      const el = document.getElementById("main-logo");

      if (el !== null) {
        if (window.innerWidth < 576) {  
          el.classList.remove("hero-logo");
          el.classList.add("hero-logo-sm");
        } else {
          el.classList.remove("hero-logo-sm");
          el.classList.add("hero-logo");
        }
      }
    }

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    const goHome = () => {
      location.href = '/'
    }

    return {
      navMenuItems,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      goHome,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-application .app-system-bar.app-system-bar-boxed > div:first-child {
  margin-left: inherit;
}
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.hero-logo-sm {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  top: 25px;
}
.hero-logo-sm>img {
  height: 135px;
}

.hero-logo {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  top: 5px;
}
.hero-logo>a>img {
  height: 175px;
}
.take-img {
  /* Set a specific height */
  min-height: 200px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url('../../../../assets/images/pages/homebg-take.png');
}
.sell-img {
  /* Set a specific height */
  min-height: 200px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url('../../../../assets/images/pages/homebg-sell.png');
}
.hero-image {
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
