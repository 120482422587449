import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateCurrentUser,
} from 'firebase/auth'
import { auth, dbapp, doc, getDoc, setDoc } from '../firebaseConfig'

export default {
  namespaced: true,
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },

  getters: {
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user.data = payload
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
  },
  actions: {
    async register(context, { email, password, name }) {
      await createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          console.log(auth.currentUser)
          const newuserobj = {
            fullname: name,
            admin: false,
            deleted: false,
            email,
          }

          // Create User in Firestore
          setDoc(doc(dbapp, 'users', auth.currentUser.uid), newuserobj)
            .then(() => {
              sendEmailVerification(auth.currentUser)
                .then(() => auth.currentUser)
                .catch(err => {
                  throw new Error(err.message)
                })
            })
            .catch(err => {
              throw new Error(err.message)
            })
        })
        .catch(err => {
          throw new Error(err.message)
        })

      /*
      if (response) {
        context.commit('SET_USER', response.user)

        return response
      }
      throw new Error('Unable to register user')
      */
    },

    async updateName(context, { user, name }) {
      const response = await updateProfile(user, { displayName: name })
      if (response) {
        return response
      }
      throw new Error('Unable to Update profile')
    },

    async emailVerify() {
      const response = await sendEmailVerification(auth.currentUser)
      if (response) {
        return response
      }

      return response

      // throw new Error('Unable to Send Email Verification')
    },

    async passwordReset(context, { email }) {
      const response = await sendPasswordResetEmail(auth, email)
      if (response) {
        return response
      }

      return response

      // throw new Error('Unable to send password reset email')
    },

    async logIn(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password)
      if (response) {
        context.commit('SET_USER', response.user)

        return response.user
      }
      throw new Error('Login failed, please try again')
    },

    async logOut(context) {
      await signOut(auth)
      context.commit('SET_USER', null)
    },

    async fetchUserInfo(context, user) {
      const response = await getDoc(doc(dbapp, 'users', user.user.uid))
      if (response) {
        return {
          user: user.user,
          info: response.data(),
        }
      }
      throw new Error('Login failed, user information not found, please try again')
    },

    async fetchUser(context, user) {
      context.commit('SET_LOGGED_IN', user !== null)
      if (user) {
        context.commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
        })
      } else {
        context.commit('SET_USER', null)
      }
    },
  },
}
