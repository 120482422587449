const dashboard = [
  {
    path: '/dashboards/material',
    name: 'dashboard-material',
    component: () => import('@/views/dashboards/material/Material.vue'),
    meta: {
      layout: 'content',
      resource: 'Editor',
      action: 'read',
    },
  },
  {
    path: '/dashboards/admin',
    name: 'dashboard-admin',
    component: () => import('@/views/dashboards/material/AdminMaterial.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/dashboards/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboards/analytics/Analytics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/eCommerce',
    name: 'dashboard-eCommerce',
    component: () => import('@/views/dashboards/ecommerce/Ecommerce.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard
