<template>
  <layout-blank>
    <slot></slot>

    <template #navbar>
      <!--
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <div class="d-flex align-left">
          
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
          ></v-app-bar-nav-icon>
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <v-img
              :src="appLogo"
              max-height="75px"
              max-width="75px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
            <h2 class="app-title text--primary">
              {{ appName }}
            </h2>
          </router-link>
        </div>

        
        <div class="d-flex align-center">
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
      -->

      <v-col style="padding: 0px; margin: -25px;">
        <v-row>
          <v-col>
            <div id="main-logo" class="hero-logo">
              <img
                src="@/assets/images/logos/main-logo.png"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            style="padding: 0px;"
          >
            <div
              class="hero-image sell-img"
              style="text-align: center;"
            ></div>
          </v-col>
          <v-col
            cols="6"
            style="padding: 0px;"
          >
            <div
              class="hero-image take-img"
              style="text-align: center;"
            ></div>
          </v-col>
        </v-row>
      </v-col>

    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-space-between" style="font-size: 10px;">
        <div class="d-flex align-center">
          COPYRIGHT &copy; {{ new Date().getFullYear() }} Material dirt trader Inc., All rights Reserved. Material dirt trader Inc. takes no liability or responsibility for any posts or exchanges made on materialtrader.ca. By using this free platform every user of this site takes responsibility for their own exchanges and/or posts created
        </div>
      </div>
    </template>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import { mdiHeartOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref, onMounted } from 'vue'

export default {
  components: {
    LayoutBlank,
  },
  setup() {
    const shallShowFullSearch = ref(false)

    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })

    const handleResize = () => {
      const el = document.getElementById("main-logo");

      if (el !== null) {
        if (window.innerWidth < 576) {  
          el.classList.remove("hero-logo");
          el.classList.add("hero-logo-sm");
        } else {
          el.classList.remove("hero-logo-sm");
          el.classList.add("hero-logo");
        }
      }
    }

    return {
      shallShowFullSearch,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-application .app-system-bar.app-system-bar-boxed > div:first-child {
  margin-left: inherit;
}
.hero-logo-sm {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  top: 25px;
}
.hero-logo-sm>img {
  height: 135px;
}
.hero-logo {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  top: 5px;
}
.hero-logo>img {
  height: 175px;
}
.take-img {
  /* Set a specific height */
  min-height: 200px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url('../../../assets/images/pages/homebg-take.png');
}
.sell-img {
  /* Set a specific height */
  min-height: 200px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url('../../../assets/images/pages/homebg-sell.png');
}
.hero-image {
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
