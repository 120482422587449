<template>
  <layout-header-nav
    :nav-menu-items="navMenuItems"
  >
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->

    <template #navbar>
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
          ></v-app-bar-nav-icon>
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <v-img
              :src="appLogo"
              max-height="75px"
              max-width="75px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
            <h2 class="app-title text--primary">
              {{ appName }}
            </h2>
          </router-link>
        </div>

        <div class="d-flex align-center">
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="py-0">
        <div class="d-flex justify-space-between" style="font-size: 10px;">
          <div class="d-flex align-center">
            COPYRIGHT &copy; {{ new Date().getFullYear() }} Material dirt trader Inc., All rights Reserved. Material dirt trader Inc. takes no liability or responsibility for any posts or exchanges made on materialtrader.ca. By using this free platform every user of this site takes responsibility for their own exchanges and/or posts created
          </div>
        </div>
      </div>
    </template>

    <!-- App Content -->
    <template
      #v-app-content
    >
      <!--<app-customizer class="d-none d-md-block"></app-customizer>-->
    </template>
  </layout-header-nav>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import LayoutHeaderNav from '@core/layouts/variants/header/LayoutHeaderNav.vue'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import { ref, watch } from 'vue'

import { mdiHeartOutline } from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  components: {
    LayoutHeaderNav,
  },
  setup() {
    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    return {
      navMenuItems,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-application .app-system-bar.app-system-bar-boxed > div:first-child {
  margin-left: inherit;
}
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
