import { mdiAccount, mdiAccountCog, mdiFaceProfile, mdiFormatListBulleted, mdiPackageVariantClosed, mdiPlaylistPlus } from '@mdi/js'

export default [
  {
    title: 'User',
    icon: mdiAccount,
    to: 'users-edit',
    resource: 'Editor',
    action: 'read',
  }
]