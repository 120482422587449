<template>
  <v-app
    class="content-layout horizontal-nav"
    :class="{'content-full': appContentWidth === 'full'}"
    v-on="$listeners"
  >
    <!-- Navbar -->
    <!--
    <v-system-bar
      app
      height="75"
      :absolute="appBarType === 'static'"
      :class="[{'app-system-bar-boxed': appContentWidth === 'boxed'}, { 'bg-blur': appBarIsBlurred }]"
      class="app-system-bar"
    >
      <slot name="navbar"></slot>
    </v-system-bar>
    -->

    <!-- Horizontal Nav Menu -->
    <!--
    <v-app-bar
      class="navigation-menu"
      app
      height="64"
      :absolute="appBarType === 'static'"
      :elevation="$vuetify.theme.isDark ? 4 : 3"
    >
      <div
        class="horizontal-nav-menu theme--light horizontal-nav-menu-boxed mx-auto scooch-down"
      >
        <v-row>
          <v-col
            cols="12"
            md="8"
            sm="6"
            class="scooch-more-down"
          >
            <v-text-field
              v-model="search"
              clearable
              type="text"
              label="Search material by keyword"
              color="primary"
              @keyup="doSearch"
              @click:clear="cleared"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="3"
          >
            <v-select
              v-model="searchType"
              outlined
              dense
              :items="itemTypes"
              item-text="itemType"
              item-value="itemType"
              placeholder="Search by Type"
              label="Search by Type"
              hide-details="auto"
              return-object
              @change="searchByType"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="1"
            sm="3"
            class="text-center"
          >
            <v-btn
              v-if="userData"
              color="primary"
              to="/dashboards"
            >
              Dashboard
              <v-icon
                end
                class="ml-2"
              >
                {{ icons.mdiHomeOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              color="info"
              :to="{ name: 'auth-login' }"
            >
              Login
              <v-icon
                end
                class="ml-2"
              >
                {{ icons.mdiAccountCircleOutline }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>
    -->

    <slot name="v-app-content"></slot>

    <v-main>
      <app-content-container>
        <slot></slot>
      </app-content-container>
    </v-main>

    <v-footer
      v-if="footerType !== 'hidden'"
      app
      inset
      :absolute="footerType === 'static'"
      padless
      :color="footerType === 'static' ? 'transparent' : null"
    >
      <v-col cols="12">
        <slot name="footer"></slot>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import AppContentContainer from '@core/layouts/components/app-content-container/AppContentContainer.vue'
import emitter from '@core/utils/eventBus'
import { mdiAccountCircleOutline, mdiHomeOutline, mdiLogin } from '@mdi/js'
import { ref } from 'vue'

export default {
  components: {
    AppContentContainer,
  },
  inheritAttrs: false,
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    // eslint-disable-next-line object-curly-newline
    const { menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } = useAppConfig()
    const search = ref(null)
    const searchType = ref(null)

    const itemTypes = [
      {
        itemType: '',
        description: 'Search by Type',
      },
      {
        itemType: 'Raw',
        description: 'Raw material',
      },
      {
        itemType: 'Prefab',
        description: 'Prefabricated material',
      },
    ]

    const doSearch = () => {
      emitter.emit('search', search.value)
    }

    const cleared = () => {
      console.log('cleared search')
      emitter.emit('clearSearch', true)
    }

    const searchByType = value => {
      emitter.emit('searchByType', value.itemType)
    }

    return {
      search,
      searchType,
      itemTypes,
      doSearch,
      cleared,
      searchByType,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      icons: {
        mdiAccountCircleOutline,
        mdiLogin,
        mdiHomeOutline,
      },
      userData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.theme--light.v-application .app-system-bar {
  background-color: #c2a242 !important;
}
.scooch-down {
  position: relative;
  top: 8px;
}

.scooch-more-down {
  position: relative;
  top: 7px;
}

.app-content-container {
  // padding: $content-padding-horizontal-navigation-menu;
  &.app-content-container-boxed {
    // padding: $boxed-content-padding-horizontal-navigation-menu;
  }
}

@include theme(v-application) using ($material) {
  .app-system-bar {
    box-shadow: 0 1px 0 0 map-deep-get($material, 'dividers');
  }
  .v-app-bar.navigation-menu {
    border-bottom: thin solid map-deep-get($material, 'dividers');
  }
}

.v-application {
  // System bar
  .app-system-bar {
    padding: 0 !important;
    // border-bottom: thin solid rgba(94, 86, 105, 0.14);
    // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);

    &.app-system-bar-boxed {
      ::v-deep > div:first-child {
        padding: $boxed-content-padding-horizontal-navigation-menu !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    ::v-deep > div:first-child {
      padding-left: $content-padding-vertical-navigation-menu !important;
      padding-right: $content-padding-vertical-navigation-menu !important;
    }
  }

  // App Bar
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding: 0;
    }

    .horizontal-nav-menu {
      padding-left: $content-padding-vertical-navigation-menu !important;
      padding-right: $content-padding-vertical-navigation-menu !important;

      &.horizontal-nav-menu-boxed {
        // width: 1440px;
        width: 100%;
        padding: $boxed-content-padding-horizontal-navigation-menu !important;
      }
    }
  }

  // @media screen and (max-width: 1711px) {
  //   margin-left: 1.5rem !important;
  //   margin-right: 1.5rem !important;
  // }

  // Footer
  .v-footer > div {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    // Padding value is from `$boxed-content-padding-horizontal-navigation-menu`
    // We will keep top and buttom padding value of footer as it is
    padding-left: 3rem;
    padding-right: 3rem;

    @at-root .content-layout.content-full {
      .v-footer > div {
        max-width: unset;
        padding-left: $content-padding-vertical-navigation-menu !important;
        padding-right: $content-padding-vertical-navigation-menu !important;
      }
    }
  }
}
</style>
