import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/styles/styles.scss'

import Vue from 'vue'
import * as VueGoogleMaps from 'vue3-google-maps'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAKlWvmHEN9gioKR4YA_GVfcAWc8dkFj4U',
    libraries: 'places',
  },
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
